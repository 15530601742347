import { Fragment, useEffect, useState } from "react";
import { useFetchUser } from "../../lib/user";
import { HashLoader } from "react-spinners";
import { ApplicationCasesTypes } from "../CMS/shared/CMSConstants";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import Button from "components/CMS/shared/Button";
import { checkIfUserHasCallsRole } from "lib/utils/helper";

const Layout = ({ lsqHandler, children, addPadding = true }) => {
  const [isCallsViewAllowed, setIsCallsViewAllowed] = useState(false);
  const { user, loading } = useFetchUser();
  const userIsLoggedIn = !loading && user;

  useEffect(() => {
    setIsCallsViewAllowed(checkIfUserHasCallsRole());
  }, []);

  return (
    <Fragment>
      <nav className="flex items-center min-h-[50px] mb-[20px] border-transparent border border-solid rounded bg-[#f8f8f8] border-[#e7e7e7]">
        <div className="mx-auto px-[15px] flex w-full h-full items-center">
          <div className="mx-0 w-[200px]">
            <img className="logo" src="/assets/logo.svg" />
          </div>
          <div className="flex w-full h-full justify-between items-center">
            <div className="flex items-center gap-4">
              <ul>
                <li>
                  <a className="ml-2 text-[#777] text-[14px]" href="/">
                    Home
                  </a>
                </li>
              </ul>
              {lsqHandler && (
                <ul>
                  <li>
                    <Button
                      className="bg-[#337ab7] border rounded p-2 text-white"
                      onClick={lsqHandler}
                    >
                      Sync to LSQ
                    </Button>
                  </li>
                </ul>
              )}
            </div>
            <ul className="flex gap-8">
              {isCallsViewAllowed && (
                <li>
                  <a className="text-[#777] text-[14px]" href="/calls-data">
                    Call recordings
                  </a>
                </li>
              )}
              <li>
                <a
                  className="text-[#777] text-[14px]"
                  href={`/cms/${ApplicationCasesTypes?.MY_CASES?.key?.toLowerCase()}`}
                >
                  Visit CMS
                </a>
              </li>
              <li>
                {userIsLoggedIn ? (
                  <a className="text-[#777] text-[14px]" href="/api/logout">
                    <LogoutIcon />
                    Logout
                  </a>
                ) : (
                  <a className="text-[#777] text-[14px]" href="/login">
                    <LoginIcon />
                    Login
                  </a>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className={`${addPadding ? "p-4" : ""} text-sm`}>
        {loading && (
          <div className="w-full h-screen fixed inset-0 flex justify-center items-center">
            <HashLoader color="#443eff"/>
          </div>
        )}
        {!loading &&
        userIsLoggedIn &&
        children(isCallsViewAllowed)}
        {!loading && !userIsLoggedIn && <LoginNotice/>}
      </div>
    </Fragment>
  );
};

export default Layout;

const LoginNotice = (props) => (
  <div className="bg-gray-100 min-h-screen pb-10">
    <div className="empty-container">
      <div>
        <div className="offer-outer-container pt-24">
          <div className="box-white px-4 sm:px-12 pb-1 mb-4 mt-auto pt-auto">
            <div className="title-holder w-full text-center text-lg">
              <p>You are not Authorized</p>
              <span>Please login to continue</span>
            </div>
            <div className="subtitle-holder w-full text-center text-base sm:px-4 mb-8">
              <span>
                Click{" "}
                <a className="underline text-blue-500" href="/login">
                  here
                </a>{" "}
                to login
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
