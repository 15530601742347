// TODO: All these inputs are tightly couplde with react-final-form fields. Need to make them agnostic of that
import React from 'react';
import { isEmpty } from 'lodash';
import { validateForNumber } from "lib/utils/helper.js"

export const Input = ({
                        input,
                        meta,
                        label,
                        disabled,
                        isRequired,
                        placeholder,
                        ...rest
                      }) => {
  const hasError = meta && meta.touched && meta.error;
  const value = (input && input.value !== "") || rest.value;
  const name = (input && input.name) || rest.name;
  const maxLength = rest.maxLength || 1000

  return (
    <div className="relative">
      <div className={value ? "is-filled" : ""}>
        <label
          className="absolute text-xxs inline-block px-4 text-gray-200 opacity-0 cursor-auto top-0 mt-1 font-bold"
          htmlFor={`field-${name}`}
        >
          {label}
        </label>
        <input
          id={`field-${name}`}
          className={`h-14 px-4 pt-1 border rounded w-full text-base text-gray-700 leading-tight ${
            hasError ? "border-red-600" : "border-gray-300"
          }`}
          placeholder={placeholder ? placeholder : label}
          disabled={disabled}
          autoFocus={rest.autoFocus}
          maxLength={maxLength}
          {...input}
          onKeyDown={(event) => {
            if (event.target.type === "number" && validateForNumber(event.key)) {
              event.preventDefault();
            }
          }}
          onWheel={(event) => {
            event.preventDefault();
            event.target.blur();
          }}
        />
      </div>
      {hasError && !meta.active && (
        <p className="mt-1 text-base text-red-600">{meta.error}</p>
      )}
      {meta.active && rest.helperText && (
        <p className="text-base text-gray-200">{rest.helperText}</p>
      )}
      <style jsx>{`
        label {
          transition: all 200ms ease-in;
          transform: translate3d(0, 0.25rem, 0);
        }
        input {
          transition: all 200ms ease-out;
          box-sizing: border-box;
          cursor: ${disabled ? "not-allowed !important" : "inherit"}; 
        }
        ::placeholder {
          color: gray;
          opacity: 1; 
        }
        input:focus {
          box-shadow: 0px 2px 4px rgba(130, 136, 148, 0.16),
            0px 0px 1px rgba(130, 136, 148, 0.16);
          border-color: #0da2f7;
        }
        .is-filled input {
          padding-top: 0.75rem;
        }
        .is-filled label {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0;
        }
      `}</style>
    </div>
  );
};

export const TextInput = ({
  name,
  label,
  placeholder,
  icon,
  meta,
  ...rest
}) => {
  const hasError = meta && meta.touched && meta.error;
  return (
    <div
      className={`relative ${
        isEmpty(rest.value || rest.defaultValue) ? '' : 'is-filled'
      }`}
    >
      <label
        className="absolute text-xxs inline-block px-4 text-gray-200 opacity-0 cursor-auto top-0 mt-2"
        htmlFor={`field-${name}`}
      >
        {label}
      </label>
      <input
        id={`field-${name}`}
        className={`h-12 px-4 pt-1 border rounded w-full text-base text-gray-700 leading-tight ${
          hasError ? 'border-red-600' : 'border-gray-300'
        }`}
        placeholder={label}
        {...rest}
        onKeyDown={(event) => {
          if (event.target.type === "number" && validateForNumber(event.key)) {
            event.preventDefault();
          }
        }}
        onWheel={(event) => {
          event.preventDefault();
          event.target.blur();
        }}
      />
      {icon && <div className="absolute top-0 right-0 mr-4 mt-4">{icon}</div>}
      <style jsx>{`
        label {
          transition: all 200ms ease-in;
          transform: translate3d(0, 0.25rem, 0);
        }
        input {
          transition: all 200ms ease-out;
          box-sizing: border-box;
        }
        ::placeholder {
          color: gray;
          opacity: 1; 
        }
        input:focus {
          box-shadow: 0px 2px 4px rgba(130, 136, 148, 0.16),
            0px 0px 1px rgba(130, 136, 148, 0.16);
          border-color: #0da2f7;
        }
        .is-filled input {
          padding-top: 1.25rem;
        }
        .is-filled label {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      `}</style>
    </div>
  );
};

export const InputWithAction = ({ action, onSubmit, ...rest }) => {
  return (
    <div className="relative">
      <TextInput 
        onKeyPress={e => {
          if (e.charCode === 13) {
            e.preventDefault();
            onSubmit(rest.value)
          }
        }}
        {...rest}
      />
      <button
        className={`absolute top-0 right-0 h-7 md:h-6 px-3 rounded-sm cursor-pointer text-xs mt-2_5 md:mt-3 ${
          rest.disabled ? 'text-blue' : 'bg-blue-700 text-white mr-2_5 md:mr-3'
        }`}
        onClick={e => {
          e.preventDefault();
          onSubmit(rest.value);
        }}
      >
        {action}
      </button>
    </div>
  );
};
