import React, {createContext} from 'react';


const FormContext = createContext();

const Title = ({children}) => (
  <h1
    className={`px-5 lg:px-0 text-base text-primary mb-1 tracking-wide`}
  >
    {children}
  </h1>
);

const Subheading = ({children}) => (
  <p
    className="px-5 lg:px-0 md:text-2xl text-gray-700 md:text-gray-800 md:font-semibold md:tracking-tight leading-snug md:leading-none">
    {children}
  </p>
);

const Section = ({children}) => (
  <div className="bounding-box bg-white rounded-none md:rounded mb-0 mt-4 md:my-4 pt-4 px-5 pb-10 md:py-[25px] md:px-8">
    {children}
  </div>
);

const Fieldset = ({children}) => (
  <fieldset
    className={''}
  >
    {children}
  </fieldset>
);

const Legend = ({last, children}) => (
  <legend
    className={`text-2xl mb-4 leading-none w-full font-inter text-gray-900 border-b legend${
      last ? 'md:mb-0' : 'md:mb-6'
    }`}
  >
    {children}
  </legend>
);

const FormSection = ({
                       title,
                       subheading,
                       children,
                     }) => {
  return (
    <FormContext.Provider>
      <div className="max-w-page-width m-auto">
        <div className="p-header-height md:p-0 ipad-view">
          {title && (<div className="mb-4 pt-10 ipad-view-course-selection">
            <Title>{title}</Title>
            <Subheading>{subheading}</Subheading>
          </div>)}
          <div className="w-full sm:w-form-width inline-block">{children}</div>

        </div>
      </div>
    </FormContext.Provider>
  );
};

export {
  Title,
  Subheading,
  Section,
  Fieldset,
  Legend,
  FormSection,
  FormContext,
};
