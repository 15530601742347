import makeStyles from "@mui/styles/makeStyles";

const useTabStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: "100%",
    margin: "3rem 6rem",
  },
  tab: {
    fontSize: "1.1rem",
    outline: "none",
  },
});

export default useTabStyles;
