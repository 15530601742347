import axios from "axios";
import { useEffect, useState } from "react";

export async function fetchUser() {
  if (typeof window !== "undefined" && window.__user) {
    return window.__user;
  }

  try {
    const res = await axios.get("/api/me");
    const json = res.data;

    if (typeof window !== "undefined") {
      window.__user = json;
    }
    return json;
  } catch (error) {
    if (typeof window !== "undefined") {
      delete window.__user;
    }
    return null;
  }
}

export function useFetchUser({ required } = {}) {
  const [loading, setLoading] = useState(
    () => !(typeof window !== "undefined" && window.__user)
  );
  const [user, setUser] = useState(() => {
    if (typeof window === "undefined") {
      return null;
    }

    return window.__user || null;
  });

  useEffect(() => {
    if (!loading && user) {
      return;
    }
    setLoading(true);
    let isMounted = true;

    fetchUser().then((user) => {
      // Only set the user if the component is still mounted
      if (isMounted) {
        // When the user is not logged in but login is required
        if (required && !user) {
          window.location.href = "/api/login";
          return;
        }
        console.log(user);
        setUser(user);
        setLoading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return { user, loading };
}
